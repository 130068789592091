import idpApi from "./idp-api";
import api from './api'

class AuthService {

    getTenant = (email: string) => {
        return idpApi.get(`/tenants/exist/${email}`);
    }

    getTenantDetails = (tenantId: string) => {
        return idpApi.get(`/tenants/${tenantId}`);
    }

    updateTenantInfo = (tenantId: string, data: any) => {
        return idpApi.patch(`/tenants/${tenantId}`, data);
    }

    getSubscriptions = () => {
        return idpApi.get(`/subscriptions`);
    }

    getArgocdSatatus = (subscriptionId: string) => {
        return idpApi.get(`/subscriptions/argo-status/${subscriptionId}`)
    }

    getSubscription = (subscription: string) => {
        return api.get(`/subscriptions/${subscription}`);
    }

    getSubscriptionFlags = (subscription: string) => {
        return idpApi.get(`/subscriptions/${subscription}/flags`);
    }

    listUsers = (subscription: string, pageNumber?: number, pageSize?: number) => {
        return idpApi.get(`/user/subscriptions/${subscription}/users?pageNumber=${pageNumber}&pageSize=${pageSize}`);
    }

    listMasterUser = (pageNumber?: number, pageSize?: number) => {
        return api.get(`/users?pageNumber=${pageNumber}&pageSize=${pageSize}`);
    }

    // IDP Call
    deleteUser = (subscription: string, uid: string, partnerId?: string) => {
        let url = `/user/subscriptions/${subscription}/users/${uid}`;
        // if(partnerId) url += `?partnerId=${partnerId}`
        return idpApi.delete(url);
    }

    // IDP Call
    createUsers = (subscription: string, data: any) => {
        return idpApi.post(`/user/subscriptions/${subscription}/users`, data);
    }

    assignRole = (subscription: string, uid: string, data: any) => {
        return idpApi.patch(`/user/subscriptions/${subscription}/users/${uid}`, data);
    }

    disableEnableUser = (uid: string, disabled: boolean) => {
        return idpApi.patch(`/user/disable-user/${uid}`, { disabled });
    }

    // checkUserExist = (subscription: string, email: string,) => {
    //     return idpApi.get(`/user/subscriptions/${subscription}/users/exist/${email}`);
    // }

    resetPassword = (uid: string, password?: string) => {
        return idpApi.patch(`/user/reset-password/${uid}`, { password })
    }

    setNewPassword = (data: any) => {
        return idpApi.post('/user/set-password', data);
    }

    setAccessControl = (subscription: string) => {
        return idpApi.patch(`/user/subscriptions/${subscription}/set-access-control`)
    }

    logSignIn = () => {
        return idpApi.post(`/user/logs`, { time: Date.now() })
    }

    sendMessage = (data: any) => {
        return idpApi.post(`/support`, data)
    }

    getAudits = (pageNumber: number, type?: string) => {
        let url = `/audits?pageNumber=${pageNumber}`;
        if(type) {
            url += `&type=${type}`;
        }
        return idpApi.get(url)
    }

    forgotPassword = (email: string) => {
        const data = { email }
        return idpApi.post(`/user/forgot-password`, data)
    }
}

export default new AuthService();
