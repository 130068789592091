export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export const stringToColor = (string: string) => {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }

export const truncateTextInMiddle = (value: string, first: number = 10, last: number = 10) => `${value.slice(0, first)}...${value.slice(-last)}`;

export const truncateText = (value: string, count: number = 30) => `${value.slice(0, count)}...`;

export const getDomainFromEmail = (email: string) => {
  let domain = email.split('@')[1];
  domain = domain.replace('.', '-');
  return domain;
}

export const daysInMonth = (month: number, year: number) => {
  return new Date(year, month, 0).getDate();
}

export const addDaystoDate = (days: number) => {
  const date = new Date();
  const theDayOfTheMonthOnNextWeek = date.getDate() + days;
  date.setDate(theDayOfTheMonthOnNextWeek)
  return date.getTime();
}

export const getCurrentMonthDays = () => {
  const date = new Date();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return new Date(year, month, 0).getDate();
}

export const groupBy = (array: any[], field: string) => {
  return array.reduce((a: any, c: any ) => {
    if(a[c[field]]) {
      a[c[field]].push(c)
    }else {
      a[c[field]] = [c]
    }
    return a
  }, {});
}

export const getFormatedDateString = (date: Date) => {
  const todayDateString = new Date().toDateString();

  const yesteday = new Date().setDate(new Date().getDate() - 1);
  const yesterdayDateString = new Date(yesteday).toDateString();

  const dateString = date.toDateString();

  return dateString === todayDateString ? 'Today' : dateString === yesterdayDateString ? 'Yesterday' : dateString
}

export const isRegexValid = (regexString: string) => {
  try {
    new RegExp(regexString);
    return true;
  } catch (e) {
    return false;
  }
}

export const getUniqueId = (prefix = 'id-') => {
  return prefix + Math.random().toString(16).slice(-4)
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}