import { Button, ButtonProps, CircularProgress } from "@mui/material";

interface Props extends ButtonProps{
  loading: boolean
}

const style = { 
  "&.Mui-disabled": {
    bgcolor: 'primary.main',
    color: '#fff',
    opacity: 0.6
  }
}

const LoadingButton = (props: Props) => {
    const { loading, children, disabled = false, ...rest } = props;
    
    return (
      <Button sx={style} disabled={disabled || loading} {...rest} variant="contained">
          {
            loading &&
            <CircularProgress thickness={6} sx={{ marginRight: '.5rem', color: '#fff' }} size={16} />
          }
          {children}
      </Button>
    )
}   

export default LoadingButton;
