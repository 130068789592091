import { Box, CircularProgress } from '@mui/material';

interface Props {
  height?: string
}

const PageLoader = (props: Props) => {
  const { height = '50vh' } = props
  return (
    <Box sx={{ color: 'grey.500', display: 'flex', height: height }}  >
        <CircularProgress color="primary" size={28} thickness={6} sx={{ margin: 'auto' }} />
    </Box>
  )
}

export default PageLoader;
