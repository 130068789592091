import { SvgIcon, SvgIconProps } from '@mui/material'

const SQRTIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon fill="#000000" width="800px" height="800px" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg" {...props} >
        <line id="primary" x1="14" y1="12" x2="18" y2="16" style={{fill: 'none', stroke: 'rgb(0, 0, 0)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: 2}}></line>
        <line id="primary-2" data-name="primary" x1="18" y1="12" x2="14" y2="16" style={{fill: 'none', stroke: 'rgb(0, 0, 0)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: 2,}}>
        </line><path id="primary-3" data-name="primary" d="M3,12H4.43a1,1,0,0,1,.86.49L8,17l2.79-9.29a1,1,0,0,1,1-.71H21" style={{fill: 'none', stroke:' rgb(0, 0, 0)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: 2,}}></path>
    </SvgIcon>
  )
}

export default SQRTIcon;
