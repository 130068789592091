import ReactDOM from 'react-dom/client';
import './index.css';
// import './bootstrap.scss';
import App from './app/App';
// import 'bootstrap/dist/js/bootstrap.min.js';
import reportWebVitals from './reportWebVitals';

import { ThemeProvider, createTheme, colors } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: "#CB6100",
      contrastText: '#fff'
    },
    secondary: {
      main: "#000",
      contrastText: '#fff'
    }
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        sizeSmall: {
          padding: '4px 16px',
        },
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.blue[700],
          textDecorationColor: colors.blue[400]
        }
      }
    },
    MuiAlert:{
      styleOverrides: {
        filledSuccess: {
          backgroundColor: '#E9FBF0',
          color: "#1e4620"
        },
        filledError:{
          backgroundColor: "rgb(253, 237, 237)",
          color: "#5f2120"
        },
        filledInfo:{
          backgroundColor: 'rgb(229, 246, 253)',
          color: "#014361"
        },
        filledWarning:{
          backgroundColor: 'rgb(255, 244, 229)',
          color: "#663c00"
        }
      }
    }
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
