import { SvgIcon, SvgIconProps } from '@mui/material'

const AbsoluteIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="700px" height="700px" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props} >
        <g>
            <path d="m528.08 101.92c-4.4805-4.4805-11.199-4.4805-15.68 0l-162.4 162.4-162.4-162.4c-4.4805-4.4805-11.199-4.4805-15.68 0s-4.4805 11.199 0 15.68l162.4 162.4-162.4 162.4c-4.4805 4.4805-4.4805 11.199 0 15.68s11.199 4.4805 15.68 0l162.4-162.4 162.4 162.4c4.4805 4.4805 11.199 4.4805 15.68 0s4.4805-11.199 0-15.68l-162.4-162.4 162.4-162.4c4.4805-4.4805 4.4805-11.203 0-15.68z"/>
            <path d="m109.2 28c-6.1602 0-11.199 5.0391-11.199 11.199v481.6c0 6.1602 5.0391 11.199 11.199 11.199 6.1602 0 11.199-5.0391 11.199-11.199v-481.6c0-6.1602-5.0391-11.199-11.199-11.199z"/>
            <path d="m590.8 28c-6.1602 0-11.199 5.0391-11.199 11.199v481.6c0 6.1602 5.0391 11.199 11.199 11.199 6.1602 0 11.199-5.0391 11.199-11.199v-481.6c0-6.1602-5.0391-11.199-11.199-11.199z"/>
        </g>
    </SvgIcon>
  )
}

export default AbsoluteIcon;