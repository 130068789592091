import Box from "@mui/material/Box";
import { useStore } from "../store";
import Alert from "@mui/material/Alert";

export default function FeatureFlagGuard(props: any) {
  const { children, remoteConfigKey, enabled } = props;

  const { globalState: { subscriptionDetails } } = useStore();

  const hasPermission = subscriptionDetails?.flags && subscriptionDetails?.flags[remoteConfigKey] || false;

  if ((enabled && !hasPermission) || (!enabled && hasPermission)) {
    return (
      <Box p={5}>
        <Alert severity="error" variant="filled">
          No required permissions to view this page.
        </Alert>
      </Box>
    );
  }
  return children;
}
