const ENV = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  APP_URL: process.env.REACT_APP_APP_URL,
  SOCKET_URL: process.env.REACT_APP_SOCKET_URL,
  IDENTITY_API: process.env.REACT_APP_IDENTITY_API,
  STATE_MANAGE_API: process.env.REACT_APP_STATE_MANAGE_API,
  CUSTOMER_API: process.env.REACT_APP_CUSTOMER_API,
  DASHBOARD_API: process.env.REACT_APP_DASHBOARD_API,
  OMNICORE_PUBLIC_API: process.env.REACT_APP_OMNICORE_PUBLIC_API,
  OMNICORE_PRIVATE_API: process.env.REACT_APP_OMNICORE_PRIVATE_API,
  OMNICORE_API_TOKEN_URL: process.env.REACT_APP_OMNICORE_API_TOKEN_URL,
  OMNICORE_API_URL: process.env.REACT_APP_OMNICORE_API_URL,

  POSEIDEN_FOLDER_LIST: process.env.REACT_APP_POSEIDEN_FOLDER_LIST,
  POSEIDEN_FOLDER_FILES: process.env.REACT_APP_POSEIDEN_FOLDER_FILES,
  POSEIDEN_CREATE_BUCKET: process.env.REACT_APP_POSEIDEN_CREATE_BUCKET,

  OMNICORE_IDENTITY_MANAGEMENT_API_KEY:
    process.env.REACT_APP_OMNICORE_IDENTITY_MANAGEMENT_API_KEY,

  SERVICE_ACCOUNT: process.env.REACT_APP_SERVICE_ACOOUNT,
  GCS_SERVICE_ACOOUNT: process.env.REACT_APP_GCS_SERVICE_ACOOUNT,

  MQTT_HOST: process.env.REACT_APP_MQTT_HOST,
  TOKEN_SERVICE_API: process.env.REACT_APP_TOKEN_SERVICE_API,

  FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
  FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  FIREBASE_REDIRECT_URL: process.env.REACT_APP_FIREBASE_REDIRECT_URL,
  GOOGLE_MAP_API_KEY: process.env.REACT_APP_GOOGLE_MAP_API_KEY,

  ACCOUNT_ID: process.env.REACT_APP_ACCOUNT_ID,
};

export default ENV;
