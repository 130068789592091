import { Link, LinkProps } from '@mui/material';
import { Link as ReactRouterLink } from 'react-router-dom';

interface Props extends LinkProps {
    to: string,
}

const RouterLink = (props: Props) => {
  return (
    <Link component={ReactRouterLink} {...props}>
        { props.children}
    </Link>
  )
}

export default RouterLink