import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';


export default function DatePicker(props: any) {

    const { name, label, value, onChange } = props;

    const convertToDefEventPara = (name: string, value: any) => ({
        target: {
            name, value
        }
    })

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']}>
            <MuiDatePicker
                label={label} 
                value={value}
                onChange={(date: any) =>onChange(convertToDefEventPara(name, date))}
                
            />
        </DemoContainer>
        </LocalizationProvider>
    );
}

