import { Box, CircularProgress } from '@mui/material';
import { Suspense, lazy } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate, Route, Routes } from 'react-router-dom';

import PageLoader from '../components/PageLoader';
import PrivateRoute from '../components/PrivateRoute';
import PublicRoute from '../components/PublicRoute';
import { auth } from '../config/firebase';
import { permissions } from '../global/constants';
import RoutePaths from '../routes/route';
import AccessRoute from './AccessRoute';
import FeatureFlagGuard from './FeatureFlagGuard';

const ResetPassword = lazy(() => import('../pages/reset-password/ResetPassword'));
const AdvancedSearch = lazy(() => import('../pages/AdvancedSearch/AdvancedSearch'));
const RegistryLayout = lazy(() => import('../pages/registries/registry-layout'));
const Registries = lazy(() => import('../pages/registries/registries'));
const NewRegistry = lazy(() => import('../pages/new-registry/new-registry'));
const RegistryOverview = lazy(() => import('../pages/registry-overview/registry-overview'));
const DevicesLayout = lazy(() => import('../pages/devices/devices-layout'));
const Devices = lazy(() => import('../pages/devices/devices'))
const TcpDevices=lazy(()=>import('../pages/devices/tcp-devices'))
const NewDevice = lazy(() => import('../pages/new-device/new-device'));
const DeviceOverview = lazy(() => import('../pages/device-overview/DeviceOverview'));

const Login = lazy(() => import('../pages').then(module => ({ default: module.Login })));
const ForgotPassword = lazy(() => import('../pages').then(module => ({ default: module.ForgotPassword })));
const Dashboard = lazy(() => import('../pages').then(module => ({ default: module.Dashboard })));
const IAMAdminLayout = lazy(() => import('../pages').then(module => ({ default: module.IAMAdminLayout })));
const RolesLayout = lazy(() => import('../pages').then(module => ({ default: module.RolesLayout })));
const Roles = lazy(() => import('../pages').then(module => ({ default: module.Roles })));
const NewRole = lazy(() => import('../pages').then(module => ({ default: module.NewRole })));
const SinksLayout = lazy(() => import('../pages').then(module => ({ default: module.SinksLayout })));
const Sinks = lazy(() => import('../pages').then(module => ({ default: module.Sinks })));
const AddSinks = lazy(() => import('../pages').then(module => ({ default: module.AddSinks })));
const AuditLogLayout = lazy(() => import('../pages').then(module => ({ default: module.AuditLogLayout })));
const AuditLogs = lazy(() => import('../pages').then(module => ({ default: module.AuditLogs })));
const RoleDetails = lazy(() => import('../pages').then(module => ({ default: module.RoleDetails })));
const ProviderLayout = lazy(() => import('../pages').then(module => ({ default: module.ProviderLayout })));
const Providers = lazy(() => import('../pages').then(module => ({ default: module.Providers })));
const CreateProvider = lazy(() => import('../pages').then(module => ({ default: module.CreateProvider })));
const UserLayout = lazy(() => import('../pages').then(module => ({ default: module.UserLayout })));
const Users = lazy(() => import('../pages').then(module => ({ default: module.Users })));
const Gateways = lazy(() => import('../pages').then(module => ({ default: module.Gateways })));
const CreateGateway = lazy(() => import('../pages').then(module => ({ default: module.CreateGateway })));
const GatewayOverview = lazy(() => import('../pages').then(module => ({ default: module.GatewayOverview })));
const SinkDetails = lazy(() => import('../pages').then(module => ({ default: module.SinkDetails })));
const APIKeyLayout = lazy(() => import('../pages').then(module => ({ default: module.APIKeyLayout })));
const APIKeys = lazy(() => import('../pages').then(module => ({ default: module.APIKeys })));
const RuleEditorLayout = lazy(() => import('../pages').then(module => ({ default: module.RuleEditorLayout })));
const RuleEditor = lazy(() => import('../pages').then(module => ({ default: module.RuleEditor })));
const Partners = lazy(() => import('../pages').then(module => ({ default: module.Partners })));
const ContactUs = lazy(() => import('../pages').then(module => ({ default: module.ContactUs })));
// const MigrationLayout = lazy(() => import('../pages').then(module => ({ default: module.MigrationLayout })));
// const Migrations = lazy(() => import('../pages').then(module => ({ default: module.Migrations })));
// const MigrationBatchDetails = lazy(() => import('../pages').then(module => ({ default: module.MigrationBatchDetails })));
const RegistryAudit = lazy(() => import('../pages').then(module => ({ default: module.RegistryAudit })));
const MTAPIKeyLayout = lazy(() => import('../pages').then(module => ({ default: module.MTAPIKeyLayout })));
const MTAPIKeys = lazy(() => import('../pages').then(module => ({ default: module.MTAPIKeys })));
// const OmniHubLayout = lazy(() => import('../pages').then(module => ({ default: module.OmniHubLayout })));
// const OmniHubTopics = lazy(() => import('../pages').then(module => ({ default: module.OmniHubTopics })));
// const OmniHubJobs = lazy(() => import('../pages').then(module => ({ default: module.OmniHubJobs })));
// const OmniHubFlows = lazy(() => import('../pages').then(module => ({ default: module.OmniHubFlows })));
// const CreateOmniHubFlow = lazy(() => import('../pages').then(module => ({ default: module.CreateOmniHubFlow })));
// const OmniHubPOIs = lazy(() => import('../pages/omnihub/POIs/OmniHubPOI'));
const VaultLayout = lazy(() => import('../pages').then(module => ({ default: module.VaultLayout })));
const Vault = lazy(() => import('../pages').then(module => ({ default: module.Vault })));
const FolderOverview = lazy(() => import('../pages').then(module => ({ default: module.FolderOverview })));
// const Operations = lazy(() => import('../pages').then(module => ({ default: module.Operations })));
const Configurations = lazy(() => import('../pages').then(module => ({ default: module.Configurations })));
const ConfigurationDetails = lazy(() => import('../pages').then(module => ({ default: module.ConfigurationDetails })));
const AddConfig = lazy(() => import('../pages').then(module => ({ default: module.AddConfig })));
const Replays = lazy(() => import('../pages').then(module => ({ default: module.Replays })));
const CreateReplay = lazy(() => import('../pages').then(module => ({ default: module.CreateReplay })));
const Exports = lazy(() => import('../pages').then(module => ({ default: module.Exports })));
const CreateExport = lazy(() => import('../pages').then(module => ({ default: module.CreateExport })));
// const FlowOveriview = lazy(() => import('../pages').then(module => ({ default: module.FlowOveriview })));
// const CentralLookup = lazy(() => import('../pages').then(module => ({ default: module.CentralLookup })));
// const TwinLayout = lazy(() => import('../pages').then(module => ({ default: module.TwinLayout })));
// const TwinDashboard = lazy(() => import('../pages').then(module => ({ default: module.TwinDashboard })));
const Folders = lazy(() => import('../pages').then(module => ({ default: module.Folders })));

const Report = lazy(() => import('../pages').then(module => ({ default: module.Report })));
const ReportLayout = lazy(() => import('../pages').then(module => ({ default: module.ReportLayout })));

const AppRoutes = () => {

  const [user, loading] = useAuthState(auth);
  if (loading) {
    return (
      <Box sx={{ color: 'grey.500', display: 'flex', height: 'calc(100vh - 64px)' }}  >
        <CircularProgress color="primary" sx={{ margin: 'auto' }} />
      </Box>
    )
  }

  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
        <Route path="/forgot-password" element={<PublicRoute><ForgotPassword /></PublicRoute>} />
        <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path="/search" element={<PrivateRoute><AdvancedSearch /></PrivateRoute>} />

        <Route path={RoutePaths.registries} element={<PrivateRoute><RegistryLayout /></PrivateRoute>}>
          <Route index element={<AccessRoute permission={permissions.listRegistry}><Registries /></AccessRoute>} />
          <Route path={RoutePaths.new_registry} element={<AccessRoute permission={permissions.createRegistry}><NewRegistry /></AccessRoute>} />
          <Route path=":registryId/overview" element={<AccessRoute permission={permissions.getRegistry}><RegistryOverview /></AccessRoute>} />
          <Route path=":registryId/edit" element={<AccessRoute permission={permissions.updateRegistry}><NewRegistry editMode /></AccessRoute>} />
          <Route path=":registryId/devices" element={<DevicesLayout />}>
            <Route index element={<AccessRoute permission={permissions.listDevice}><Devices /></AccessRoute>} />
            <Route path="new" element={<AccessRoute permission={permissions.createDevice}><FeatureFlagGuard remoteConfigKey="MQTTDisabled" enabled={false}><NewDevice /></FeatureFlagGuard></AccessRoute>} />
            <Route path=":deviceId/overview" element={<AccessRoute permission={permissions.getDevice}><DeviceOverview /></AccessRoute>} />
            <Route path=":deviceId/edit" element={<AccessRoute permission={permissions.updateDevice}><NewDevice editMode/></AccessRoute>} />
            <Route path="tcp" >
              <Route path="new" element={<AccessRoute permission={permissions.createDevice}><FeatureFlagGuard remoteConfigKey="TcpUdpEnabled" enabled={true}><TcpDevices/></FeatureFlagGuard></AccessRoute>}/>
            </Route>
          </Route>
          <Route path=":registryId/gateways">
            <Route index element={<AccessRoute permission={permissions.listDevice}><Gateways /></AccessRoute>} />
            <Route path="new" element={<AccessRoute permission={permissions.createDevice}><CreateGateway /></AccessRoute>} />
            <Route path=":gatewayId/overview" element={<AccessRoute permission={permissions.getDevice}><GatewayOverview /></AccessRoute>} />
            <Route path=":gatewayId/edit" element={<AccessRoute permission={permissions.updateDevice}><CreateGateway editMode /></AccessRoute>} />
          </Route>
          <Route path=":registryId/audit" element={<AccessRoute permission={permissions.listAudits}><RegistryAudit /></AccessRoute>}></Route>
        </Route>
        
        <Route path="/reports" element={<PrivateRoute><ReportLayout /></PrivateRoute>}>
          <Route index element={<Report />}/>
        </Route>
        <Route path="/sinks" element={<PrivateRoute><SinksLayout /></PrivateRoute>}>
          <Route index element={<AccessRoute permission={permissions.listSink}><Sinks /></AccessRoute>} />
          <Route path='add' element={<AccessRoute permission={permissions.createSink}><AddSinks /></AccessRoute>} />
          <Route path=':connectorId/details' element={<AccessRoute permission={permissions.getSink}><SinkDetails /></AccessRoute>} />
        </Route>
        <Route path="/api-keys" element={<PrivateRoute><APIKeyLayout /></PrivateRoute>}>
          <Route index element={<AccessRoute permission={permissions.listAPIKey}><APIKeys /></AccessRoute>} />
        </Route>
        <Route path="/api-clients" element={<PrivateRoute><MTAPIKeyLayout /></PrivateRoute>}>
          <Route index element={<AccessRoute permission={permissions.listAPIKey}><MTAPIKeys /></AccessRoute>} />
        </Route>
        {/* <Route path="/migrations" element={<PrivateRoute><MigrationLayout /></PrivateRoute>}>
          <Route index element={<AccessRoute permission={permissions.listMigrationBatches}><Migrations /></AccessRoute>} />
          <Route path="batch/:batchId/details" element={<AccessRoute permission={permissions.getMigrationBatch}><MigrationBatchDetails /></AccessRoute>} />
        </Route> */}
        <Route path="/set-password/:token" element={<PublicRoute><ResetPassword /></PublicRoute>} />
        {/* <Route path="/marina" element={<PrivateRoute><OmniHubLayout /></PrivateRoute>}>
          <Route index element={<Navigate to="topics" replace />} />
          <Route path="topics" element={<OmniHubTopics />} />
          <Route path="flows">
            <Route index element={<OmniHubFlows />} />
            <Route path="create" element={<CreateOmniHubFlow />} />
            <Route path=":flowId/diagram" element={<FlowOveriview />} />
            <Route path=":flowId/edit" element={<CreateOmniHubFlow editMode />} />
          </Route>
          <Route path="pois" element={<OmniHubPOIs />} />
          <Route path="jobs" element={<OmniHubJobs />} />
          <Route path="lookup" element={<CentralLookup />} />
        </Route>
        <Route path="/twin" element={<PrivateRoute><TwinLayout /></PrivateRoute>}>
          <Route index element={<Navigate to="dashboard" replace />} />
          <Route path="dashboard" element={<TwinDashboard />} />
        </Route> */}
        <Route path="/vault" element={<PrivateRoute><VaultLayout /></PrivateRoute>}>
          <Route index element={<Vault />} />
          <Route path="configurations" element={<Configurations />}/>
          <Route path="replay" element={<Replays />}/>
          <Route path="replay/create" element={<CreateReplay />}/>
          <Route path="exports" element={<Exports />}/>
          <Route path="exports/create" element={<CreateExport />}/>
          <Route path="configurations/add" element={<AddConfig />}/>
          <Route path="configurations/:configId/details" element={<ConfigurationDetails />} />
          <Route path="folder/:folderId/overview" element={<FolderOverview />} />
          <Route path="folders" element={<Folders />} />
        </Route>
        <Route path="/rule-editor" element={<PrivateRoute><RuleEditorLayout /></PrivateRoute>}>
          <Route index element={<RuleEditor />} />
        </Route>
        <Route path="/iam-admin" element={<PrivateRoute><IAMAdminLayout /></PrivateRoute>}>
          <Route index element={<Navigate to="users" />} />
          <Route path="providers" element={<ProviderLayout />}>
            <Route index element={<AccessRoute permission={permissions.listProvider}><Providers /></AccessRoute>} />
            <Route path='create' element={<AccessRoute permission={permissions.createProvider}><CreateProvider /></AccessRoute>} />
            <Route path=':providerId/edit' element={<AccessRoute permission={permissions.updateProvider}><CreateProvider editMode /></AccessRoute>} />
          </Route>
          <Route path="users" element={<UserLayout />}>
            <Route index element={<AccessRoute permission={permissions.listUser}><Users /></AccessRoute>} />
          </Route>
          <Route path="roles" element={<RolesLayout />}>
            <Route index element={<AccessRoute permission={permissions.listRole}><Roles /></AccessRoute>} />
            <Route path='create' element={<NewRole />} />
            <Route path=':roleId/details' element={<AccessRoute permission={permissions.getRole}><RoleDetails /></AccessRoute>} />
          </Route>
          <Route path="partners" element={<AccessRoute permission={permissions.listPartners}><Partners /></AccessRoute>} />
          <Route path="audit" element={<AccessRoute permission={permissions.subscriptionAudits}><AuditLogLayout /></AccessRoute>}>
            <Route index element={<AuditLogs />} />
          </Route>
        </Route>
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
    </Suspense>
  )
}

export default AppRoutes;