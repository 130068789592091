import axios from "axios";
import ENV from "../config/env";
import { auth } from "../config/firebase";

const baseURL = ENV.OMNICORE_PRIVATE_API;

class API {
  constructor() {
    axios.defaults.baseURL = baseURL;

    axios.interceptors.request.use(
      async (config: any) => {
        const token = await auth.currentUser?.getIdToken()
        const key = await sessionStorage.getItem('key')
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token
        }
        config.headers['Content-Type'] = 'application/json';
        config.headers['x-api-key'] = key
        return config
    },
      error => {
        Promise.reject(error)
      }
    )
  }

  get(url: string) {
    return axios.get(url);
  }

  post(url: string, params: any) {
    return axios.post(url, params);
  }

  put(url: string, data: any) {
    return axios.put(url, data);
  }

  patch(url: string, data: any) {
    return axios.patch(url, data);
  }

  delete(url: string, data?: any) {
    return axios.delete(url, {
      data
    });
  }
}

export default new API();
