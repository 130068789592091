import { Alert, Box } from '@mui/material';
import { useStore } from '../store';

const AccessRoute = (props: any) => {
    const { children, permission } = props;

    const { globalState: { userPermissions } } = useStore();

    if(!userPermissions.includes(permission)) {
        return (
            <Box p={5}>
                <Alert severity="error" variant="filled">
                No required permissions to view this page.
                </Alert>
            </Box>
        )
    }
    
    return children;
}

export default AccessRoute