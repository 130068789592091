import { ListSubheader, MenuItem, Select, Typography } from "@mui/material";
import TenantLogo from "../TenantLogo";

const SubSelect = (props: any) => {
    const { options, tenant, ...rest } = props;

    const result = options.reduce(function (c: any, a: any) {
      c[a.tenant] = c[a.tenant] || [];
      c[a.tenant].push(a);
      return c;
    }, Object.create(null));
    
    const Options = Object.keys(result)?.map((key: any) => {
      let cat = <ListSubheader key={key} sx={{ color: '#8c8c8c', lineHeight: '28px',  }}><em>{key}</em></ListSubheader>
      let options = result[key]?.map((item: any) => (
        <MenuItem key={item.id} disabled={item?.disabled} value={item.id} sx={{ fontSize: '95%', color: '#000', display: 'flex' }}>
          <TenantLogo logoUrl={item?.logo} size={30} defaultIconSize={30} backgroundColor="#e8e8e8" />
          <Typography component="span" ml={1}>{item.title}</Typography>
        </MenuItem>
      ));

    return [cat, ...options]
})

    return (
      <Select
        size="small"
        autoWidth
        required
        {...rest}
        sx={{
          backgroundColor: '#ebebeb',
          '&:hover': {
            backgroundColor: '#fafafa',
          },
          '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
            py: .5,
            '.MuiTypography-root': {
              fontWeight: 600, 
              ml: 2
            }
          },
          'fieldset': { border: 0}
        }}
      >
        { Options.reduce((acc, val) => acc.concat(val), []) }
      </Select>
    )
}

export default SubSelect;
