import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker  as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';


export default function DateTimePicker(props: any) {

    const { name, label, value, onChange, sx = {} } = props;

    const convertToDefEventPara = (name: string, value: any) => {
        return {
            target: {
                name, value
            }
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DateTimePicker']}>
            <MuiDateTimePicker
                label={label} 
                value={value}
                onChange={(date: any) =>onChange(convertToDefEventPara(name, date))}
                sx={{ width: '100%', '& .MuiInputBase-input': { padding: '8.5px 14px' }, ...sx }}
            />
        </DemoContainer>
        </LocalizationProvider>
    );
}

