import { CopyToClipboard } from 'react-copy-to-clipboard';

const ClipBoard = (props: any) => {
    const { text } = props;
    return (
      <CopyToClipboard text={text}
        onCopy={()=> {
    }}
      >
        {props?.children}
      </CopyToClipboard>
    )
}

export default ClipBoard;
