import { Avatar as MuiAvatar } from "@mui/material"
import { stringToColor } from "../utils/utility";

interface Props {
  name: string,
  size?: number
  fontSize?: number
}

const Avatar = (props: Props) => {
  const { name, size = 40, fontSize } = props;
  const color = stringToColor(name);
  const displayChar = name.charAt(0).toUpperCase();
  return (
    <MuiAvatar alt="Avatar" sx={{bgcolor: color, height: size, width: size, fontSize }}>{displayChar}</MuiAvatar>
  )
}

export default Avatar;
