import { Business } from '@mui/icons-material';
import { Box } from '@mui/material';

interface LogoProps {
    size?: number,
    defaultIconSize?: number,
    logoUrl: string,
    backgroundColor?: string
}

const TenantLogo = (props: LogoProps) => {

    const { size, logoUrl, backgroundColor = '#fff', defaultIconSize } = props
  return (
    <Box className="Tenant-logo" sx={{ 
            height: size, width: size, borderRadius: '50%', background: backgroundColor, position: 'relative',
            display: 'flex', justifyContent: 'center', alignItems: 'center',
            '&:hover': { 'label': { display: 'flex !important' } }
        }}>
            {
                logoUrl ?
                <img  src={logoUrl} style={{ height: '100%', width: '100%', borderRadius: '50%',
                        objectFit: 'contain',
                        padding: '2px' 
                      }} 
                     alt="logo"
                /> :
                <Business sx={{ color: '#000', fontSize: defaultIconSize, padding: '6px' }} />

            }
    </Box>
  )
}

export default TenantLogo