import { initializeApp } from 'firebase/app';
import {
    onAuthStateChanged,
    signInWithEmailAndPassword,
    getAuth,
    GoogleAuthProvider,
    OAuthProvider,
    signInWithPopup,
    signInWithRedirect,
    signOut,
    SAMLAuthProvider,
} from 'firebase/auth';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { getRemoteConfig, getAll, getValue, fetchAndActivate } from 'firebase/remote-config'

import ENV from '../config/env';

const firebaseConfig = {
    apiKey: ENV.FIREBASE_API_KEY,
    authDomain: ENV.FIREBASE_AUTH_DOMAIN,
    storageBucket: ENV.FIREBASE_STORAGE_BUCKET,
    projectId: ENV.FIREBASE_PROJECT_ID,
    appId: ENV.FIREBASE_APP_ID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const storage = getStorage(app);


const uploadFile = async (file: File, filePath: string, progressCallback: any, onFinish: any) => {
    const storageRef = ref(storage, filePath);
    const metadata = {
        contentType: file.type,
      };
    
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on('state_changed', 
        (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            progressCallback(progress);
        }, 
        (error) => {
            // Handle unsuccessful uploads
        }, 
        () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                onFinish(downloadURL)
            });
        }
    );
}

const downloadFromCloudStorage = async (filePath: string) => {
    const pathReference = ref(storage, filePath);
    try {
        const url = await getDownloadURL(pathReference)
        return url;
    } catch (error: any) {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        // switch (error.code) {
        //     case 'storage/object-not-found':
        //         // File doesn't exist
        //         break;
        //     case 'storage/unauthorized':
        //         // User doesn't have permission to access the object
        //         break;
        //     case 'storage/canceled':
        //         // User canceled the upload
        //         break;
        //     case 'storage/unknown':
        //         // Unknown error occurred, inspect the server response
        //         break;
        //     }
            return ''
    }
    
}

const provider = new GoogleAuthProvider();
const microsoft = new OAuthProvider('microsoft.com');

const signOutHandler = () => {
    signOut(auth).then(resp => {
        console.log('Sign out successfully');
    }).catch((err => {
        console.log('Sign out error', err);
    }))
}

onAuthStateChanged(auth, async (user)=> {
    if(user) {
        const token = await user?.getIdToken();
        localStorage.setItem('token', token);
    }else {
        localStorage.removeItem('token')
    }
})

const remoteConfig = getRemoteConfig(app);

if(remoteConfig && remoteConfig?.settings){
    remoteConfig.settings.minimumFetchIntervalMillis = 7200000;//2 hour interval
}

remoteConfig.defaultConfig = {
AdditionalTopicCount: 12
}

const fetchAndActivateRemoteConfig = () => {    
    fetchAndActivate(remoteConfig).then(()=> {
        getAll(remoteConfig);
    });
}

const getRemoteConfigValue = (param: string) => getValue(remoteConfig, param)

export { app, auth, onAuthStateChanged, signInWithEmailAndPassword, 
    provider, microsoft, SAMLAuthProvider, downloadFromCloudStorage,
    signInWithPopup, signInWithRedirect, signOutHandler, uploadFile, remoteConfig, getRemoteConfigValue, fetchAndActivateRemoteConfig
};