import { Email, Google, VpnLock } from "@mui/icons-material";
import { ReadyState } from "react-use-websocket";
import { Microsoft } from "../components/Icons";

export const regions = [
  { id: "us-central1", title: "us-central1" },
  { id: "europe-west1", title: "europe-west1" },
  { id: "asia-east1", title: "asia-east1" },
  { id: "asia-northeast1", title: "asia-northeast1" },
  { id: "asia-southeast1", title: "asia-southeast1" },
  { id: "australia-southeast1", title: "australia-southeast1" },
  { id: "southamerica-west1", title: "southamerica-west1" },
];

export const kinesisRegions = [
  { id: "us-east-2", title: "US East (Ohio)" },
  { id: "us-east-1", title: "US East (N. Virginia)" },
  { id: "us-west-1", title: "US West (N. California)" },
  { id: "us-west-2", title: "US West (Oregon)" },
  { id: "af-south-1", title: "Africa (Cape Town)" },
  { id: "ap-east-1", title: "Asia Pacific (Hong Kong)" },
  { id: "ap-south-2", title: "Asia Pacific (Hyderabad)" },
  { id: "ap-southeast-3", title: "Asia Pacific (Jakarta)" },
  { id: "ap-southeast-4", title: "Asia Pacific (Melbourne)" },
  { id: "ap-south-1", title: "Asia Pacific (Mumbai)" },
  { id: "ap-northeast-3", title: "Asia Pacific (Osaka)" },
  { id: "ap-northeast-2", title: "Asia Pacific (Seoul)" },
  { id: "ap-southeast-1", title: "Asia Pacific (Singapore)" },
  { id: "ap-southeast-2", title: "Asia Pacific (Sydney)" },
  { id: "ap-northeast-1", title: "Asia Pacific (Tokyo)" },
  { id: "ca-central-1", title: "Canada (Central)" },
  { id: "ca-west-1", title: "Canada West (Calgary)" },
  { id: "eu-central-1", title: "Europe (Frankfurt)" },
  { id: "eu-west-1", title: "Europe (Ireland)" },
  { id: "eu-west-2", title: "Europe (London)" },
  { id: "eu-south-1", title: "Europe (Milan)" },
  { id: "eu-west-3", title: "Europe (Paris)" },
  { id: "eu-south-2", title: "Europe (Spain)" },
  { id: "eu-north-1", title: "Europe (Stockholm)" },
  { id: "eu-central-2", title: "Europe (Zurich)" },
  { id: "il-central-1", title: "Israel (Tel Aviv)" },
  { id: "me-south-1", title: "Middle East (Bahrain)" },
  { id: "me-central-1", title: "Middle East (UAE)" },
  { id: "sa-east-1", title: "South America (São Paulo)" },
  { id: "us-gov-east-1", title: "AWS GovCloud (US-East)" },
  { id: "us-gov-west-1", title: "AWS GovCloud (US-West)" },
];

export const cloudLoggingOptions = [
  // { id: "DISABLED", title: 'Disabled' },
  { id: "ERROR", title: "Error" },
  { id: "INFO", title: "Info" },
  // { id: "DEBUG", title: 'Debug' },
];

export const messageRouteOptions = [
  { id: "GCP", title: "GCP Cloud Pub/Sub" },
  { id: "Kinesis", title: "AWS Kinesis" },
  { id: "vista", title: "Vista Connector" },
  { id: "MARINA", title: "The Marina", disabled: true },
];

export const publicKeyFormat = [
  { id: "RSA_PEM", title: "RS256" },
  { id: "ES256_PEM", title: "ES256" },
  { id: "RSA_X509_PEM", title: "RS256_X509" },
  { id: "ES256_X509_PEM", title: "ES256_X509" },
];

export const DefaultDevicePolicy = {
  Connect: true,
  PublishState: true,
  PublishEvents: true,
  PublishEventsRegex: ".*",
  PublishLoopback: true,
  SubscribeCommand: true,
  SubscribeCommandRegex: ".*",
  SubscribeConfig: true,
  SubscribeBroadcast: true,
  SubscribeBroadcastRegex: ".*",
};

export const deviceAuthenticationMethods = [
  { id: "ASSOCIATION_ONLY", title: "Association only" },
  { id: "DEVICE_AUTH_TOKEN_ONLY", title: "Device credentials only" },
  {
    id: "ASSOCIATION_AND_DEVICE_AUTH_TOKEN",
    title: "Both association and device credentials",
  },
];

export const WebsocketReadyState = {
  [ReadyState.CONNECTING]: "Connecting",
  [ReadyState.OPEN]: "Connected",
  [ReadyState.CLOSING]: "Disconnecting",
  [ReadyState.CLOSED]: "Disconnected",
  [ReadyState.UNINSTANTIATED]: "Uninstantiated",
};

export const Providers = [
  {
    id: "password",
    title: "Email / Password",
    icon: Email,
    iconColor: "",
    disabled: true,
  },
  {
    id: "google",
    title: "Google",
    icon: Google,
    iconColor: "rgb(66, 133, 244)",
    disabled: true,
  },
  { id: "microsoft", title: "Microsoft", icon: Microsoft, disabled: true },
  // { id: "facebook", title: 'Facebook', icon: Facebook, iconColor: 'rgb(59, 89, 152)'  },
  // { id: "twitter", title: 'Twitter', icon: Twitter, iconColor: 'rgb(85, 172, 238)' },
  { id: "saml", title: "SAML", icon: VpnLock, iconColor: "#000" },
];

export const Connectors = [
  { id: "pubsub", title: "Cloud Pub/Sub" },
  { id: "kinesis", title: "AWS Kinesis" },
  { id: "vista", title: "Vista Connector" },
  { id: "kafka", title: "Kafka", disabled: true },
];

export const VaultConfigTypes = [
  { id: "pubsub", title: "Cloud Pub/Sub" },
  { id: "gcs", title: "Cloud Storage" },
  { id: "kafka", title: "Kafka", disabled: true },
];

export const permissions = {
  bindGateway: "omnicore.devices.bindGateway",
  unbindGateway: "omnicore.devices.unbindGateway",
  createDevice: "omnicore.devices.create",
  deleteDevice: "omnicore.devices.delete",
  getDevice: "omnicore.devices.get",
  listDevice: "omnicore.devices.list",
  sendCommand: "omnicore.devices.sendCommand",
  updateDevice: "omnicore.devices.update",
  updateConfig: "omnicore.devices.updateConfig",
  createRegistry: "omnicore.registries.create",
  deleteRegistry: "omnicore.registries.delete",
  getRegistry: "omnicore.registries.get",
  listRegistry: "omnicore.registries.list",
  updateRegistry: "omnicore.registries.update",
  createUser: "omnicore.users.create",
  listUser: "omnicore.users.list",
  deleteUser: "omnicore.users.delete",
  updateUser: "omnicore.users.update",
  getUser: "omnicore.users.get",
  createProvider: "omnicore.providers.create",
  updateProvider: "omnicore.providers.update",
  listProvider: "omnicore.providers.list",
  deleteProvider: "omnicore.providers.delete",
  getProvider: "omnicore.providers.get",
  createRole: "omnicore.roles.create",
  updateRole: "omnicore.roles.update",
  listRole: "omnicore.roles.list",
  deleteRole: "omnicore.roles.delete",
  getRole: "omnicore.roles.get",
  createSink: "omnicore.sinks.create",
  listSink: "omnicore.sinks.list",
  deleteSink: "omnicore.sinks.delete",
  getSink: "omnicore.sinks.get",
  createAPIKey: "omnicore.apikeys.create",
  listAPIKey: "omnicore.apikeys.list",
  deleteAPIKey: "omnicore.apikeys.delete",
  getAPIKey: "omnicore.apikeys.getKey",
  getMetrics: "omnicore.metrics.get",
  updateTenant: "omnicore.tenant.update",
  resetPassword: "omnicore.users.resetPassword",
  disableUser: "omnicore.users.disableUser",
  listAudits: "omnicore.audits.list",
  createTenantAdmin: "omnicore.users.createTenantAdmin",
  deleteTenantAdmin: "omnicore.users.deleteTenantAdmin",
  updateTenantAdmin: "omnicore.users.updateTenantAdmin",
  listPartners: "omnicore.partners.list",
  createPartners: "omnicore.partners.create",
  deletePartners: "omnicore.partners.delete",

  listMigrationBatches: "omnicore.migration.listBatches",
  getMigrationKey: "omnicore.migration.getKey",
  createMigrationKey: "omnicore.migration.createKey",
  startMigration: "omnicore.migration.startMigration",
  getMigrationBatch: "omnicore.migration.getBatch",
  resolveMigrationConflict: "omnicore.migration.resolveConflict",
  subscriptionAudits: "omnicore.subscription.audits",
  tenantAudits: "omnicore.tenant.audits",
};

export const Roles = {
  Admin: [
    "omnicore.devices.bindGateway",
    "omnicore.devices.unbindGateway",
    "omnicore.devices.create",
    "omnicore.devices.delete",
    "omnicore.devices.get",
    "omnicore.devices.list",
    "omnicore.devices.sendCommand",
    "omnicore.devices.update",
    "omnicore.devices.updateConfig",
    "omnicore.registries.create",
    "omnicore.registries.delete",
    "omnicore.registries.get",
    "omnicore.registries.list",
    "omnicore.registries.update",
    "omnicore.users.create",
    "omnicore.users.list",
    "omnicore.users.delete",
    "omnicore.users.update",
    "omnicore.users.get",
    // 'omnicore.providers.create',
    // 'omnicore.providers.update',
    "omnicore.providers.list",
    // 'omnicore.providers.delete',
    // 'omnicore.providers.get',
    "omnicore.roles.create",
    "omnicore.roles.update",
    "omnicore.roles.list",
    "omnicore.roles.delete",
    "omnicore.roles.get",
    "omnicore.sinks.create",
    "omnicore.sinks.list",
    "omnicore.sinks.delete",
    "omnicore.sinks.get",
    "omnicore.apikeys.create",
    "omnicore.apikeys.list",
    "omnicore.apikeys.delete",
    "omnicore.apikeys.getKey",
    "omnicore.audits.list",
    "omnicore.partners.list",
    "omnicore.partners.create",
    "omnicore.partners.delete",
    "omnicore.metrics.get",

    permissions.listMigrationBatches,
    permissions.getMigrationKey,
    permissions.createMigrationKey,
    permissions.startMigration,
    permissions.getMigrationBatch,
    permissions.resolveMigrationConflict,

    permissions.subscriptionAudits,
  ],
  TenantAdmin: [
    "omnicore.devices.bindGateway",
    "omnicore.devices.unbindGateway",
    "omnicore.devices.create",
    "omnicore.devices.delete",
    "omnicore.devices.get",
    "omnicore.devices.list",
    "omnicore.devices.sendCommand",
    "omnicore.devices.update",
    "omnicore.devices.updateConfig",
    "omnicore.registries.create",
    "omnicore.registries.delete",
    "omnicore.registries.get",
    "omnicore.registries.list",
    "omnicore.registries.update",
    "omnicore.users.create",
    "omnicore.users.list",
    "omnicore.users.delete",
    "omnicore.users.update",
    "omnicore.users.get",
    "omnicore.providers.create",
    "omnicore.providers.update",
    "omnicore.providers.list",
    "omnicore.providers.delete",
    "omnicore.providers.get",
    "omnicore.roles.create",
    "omnicore.roles.update",
    "omnicore.roles.list",
    "omnicore.roles.delete",
    "omnicore.roles.get",
    "omnicore.sinks.create",
    "omnicore.sinks.list",
    "omnicore.sinks.delete",
    "omnicore.sinks.get",
    "omnicore.apikeys.create",
    "omnicore.apikeys.list",
    "omnicore.apikeys.delete",
    "omnicore.apikeys.getKey",
    "omnicore.audits.list",
    "omnicore.metrics.get",
    "omnicore.tenant.update",
    "omnicore.users.resetPassword",
    permissions.disableUser,
    "omnicore.users.createTenantAdmin",
    "omnicore.users.deleteTenantAdmin",
    "omnicore.users.updateTenantAdmin",
    "omnicore.partners.list",
    "omnicore.partners.create",
    "omnicore.partners.delete",

    permissions.listMigrationBatches,
    permissions.getMigrationKey,
    permissions.createMigrationKey,
    permissions.startMigration,
    permissions.getMigrationBatch,
    permissions.resolveMigrationConflict,
    permissions.subscriptionAudits,
    permissions.tenantAudits,
  ],
  Editor: [
    "omnicore.devices.bindGateway",
    "omnicore.devices.unbindGateway",
    "omnicore.devices.create",
    "omnicore.devices.delete",
    "omnicore.devices.get",
    "omnicore.devices.list",
    "omnicore.devices.sendCommand",
    "omnicore.devices.update",
    "omnicore.devices.updateConfig",
    "omnicore.registries.create",
    "omnicore.registries.delete",
    "omnicore.registries.get",
    "omnicore.registries.list",
    "omnicore.registries.update",
    "omnicore.metrics.get",
    "omnicore.sinks.list",
    "omnicore.apikeys.list",
    "omnicore.audits.list",
  ],
  Provisioner: [
    "omnicore.devices.bindGateway",
    "omnicore.devices.unbindGateway",
    "omnicore.devices.create",
    "omnicore.devices.delete",
    "omnicore.devices.get",
    "omnicore.devices.list",
    "omnicore.devices.sendCommand",
    "omnicore.devices.updateConfig",
    "omnicore.devices.update",
    "omnicore.registries.get",
    "omnicore.registries.list",
    "omnicore.metrics.get",
    "omnicore.sinks.list",
    "omnicore.apikeys.list",
  ],
  DeviceController: [
    "omnicore.devices.get",
    "omnicore.devices.list",
    "omnicore.devices.sendCommand",
    "omnicore.devices.updateConfig",
    "omnicore.registries.get",
    "omnicore.registries.list",
    "omnicore.metrics.get",
    "omnicore.sinks.list",
    "omnicore.apikeys.list",
  ],
  Viewer: [
    "omnicore.devices.get",
    "omnicore.devices.list",
    "omnicore.registries.get",
    "omnicore.registries.list",
    "omnicore.metrics.get",
    "omnicore.sinks.list",
    "omnicore.apikeys.list",
  ],
};

export const DocBaseURL = "https://docs.omnicore.korewireless.com";
export const CodeLabBaseURL = "https://codelabs.cloud.korewireless.com";

export const DocLinks = {
  CreateRegistryAndDevices: `${DocBaseURL}/docs/Universal%20Device%20Broker/MQTT%20&%20HTTP%20Devices/Guides/Provision/create-regs-devices`,
  ZeroTouchProvisioning: `${DocBaseURL}/docs/Universal%20Device%20Broker/MQTT%20&%20HTTP%20Devices/Guides/Provision/zero-touch-provisioning`,
  SinkWizard: `${DocBaseURL}/docs/Universal%20Device%20Broker/MQTT%20&%20HTTP%20Devices/Guides/Provision/prerequisites`,
  GatewayOverview: `${DocBaseURL}/docs/Universal%20Device%20Broker/MQTT%20&%20HTTP%20Devices/Guides/Gateway/Overview`,
  CreateToken: `${DocBaseURL}/docs/Universal%20Device%20Broker/MQTT%20&%20HTTP%20Devices/Guides/Connect/Authentication/Managing%20Credentials/using-jwt`,
  CloudPubSub: `${DocBaseURL}/docs/Universal%20Device%20Broker/MQTT%20&%20HTTP%20Devices/Guides/Provision/create-regs-devices#creating-a-device-registry-with-multiple-pubsub-topics`,
  APIReference: `${DocBaseURL}/docs/Universal%20Device%20Broker/MQTT%20&%20HTTP%20Devices/Guides/Security/api-security`,
  VerifyDeviceCred: `${DocBaseURL}/docs/Universal%20Device%20Broker/MQTT%20&%20HTTP%20Devices/Guides/Connect/Authentication/Managing%20Credentials/verify-device-creds`,
  IAMUsers: `${DocBaseURL}/docs/Universal%20Device%20Broker/MQTT%20&%20HTTP%20Devices/Guides/Security/iam-access-control#users`,
  IAMProviders: `${DocBaseURL}/docs/Universal%20Device%20Broker/MQTT%20&%20HTTP%20Devices/Guides/Security/iam-access-control#identity-management`,
  IAMRoles: `${DocBaseURL}/docs/Universal%20Device%20Broker/MQTT%20&%20HTTP%20Devices/Guides/Security/iam-access-control#roles`,
  IAMPartners: `${DocBaseURL}/docs/Universal%20Device%20Broker/MQTT%20&%20HTTP%20Devices/Guides/Security/iam-access-control#partner`,

  DevicePolicy: `${DocBaseURL}/docs/Universal%20Device%20Broker/MQTT%20&%20HTTP%20Devices/Guides/Connect/Authorization/Device%20Policies/index.html`,
  ErrorTopic: `${DocBaseURL}/docs/Universal%20Device%20Broker/MQTT%20&%20HTTP%20Devices/Guides/Gateway/gateway-mqtt-bridge#troubleshooting`,

  VaultDocs: `${DocBaseURL}/docs/category/vault`,
};

export const CodeLabs = {
  migration: `${CodeLabBaseURL}/codelabs/Migrating%20Devices%20From%20GCP%20IoT%20Core%20To%20OmniCore/index.html?index=..%2F..index#0`,
};

export const statusValues = [
  { id: "blocked", title: "Blocked" },
  { id: "allowed", title: "Allowed" },
];

export const type = [
  { id: "All", title: "All" },
  { id: "device", title: "Devices" },
  { id: "gateway", title: "Gateway" },
];

export const retentionType = [
  { id: 7, title: "7 days" },
  { id: 30, title: "30 days" },
  { id: 60, title: "60 days" },
  { id: 365, title: "1 year" },
  { id: 730, title: "2 year" },
  { id: 1095, title: "3 year" },
];
