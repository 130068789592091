

import { Button, createTheme, Dialog, DialogContent, DialogTitle, Grid, Stack, ThemeProvider, } from '@mui/material';
import  { FormEvent, useEffect, useState } from 'react'
import Controls from '../../../components/controls/Controls';
import { Form, useForm } from '../../../components/useForm';
import validator from 'validator'
import { toast } from '../../../components/Toast';
import { useStore } from '../../../store';
import authService from '../../../services/auth.service';
import LoadingButton from '../../../LoadingButton';

interface Props {
    open: boolean,
    handleClose: () => void,
    onSuccess?: () => void,
    uid: string
}

const theme = createTheme({
    palette: {
        primary: {
          main: "#CB6100",
          contrastText: '#fff'
        },
        secondary: {
          main: "#000",
          contrastText: '#fff'
        }
      },
  });

const ResetPasswordModal = (props: Props) => {
    const { open, uid, handleClose, onSuccess } = props;

    const { dispatch } = useStore();

    const [processing, setProcessing] = useState(false);
    
    const validate = (fieldValues = values, field = '') => {
        let temp = { ...errors }
            if (validator.isEmpty(fieldValues?.password)) {
                temp.password = "This field is required."
            }else if(fieldValues?.password?.length < 6){
                temp.password = "Password must be at least 6 characters long."
            }else {
                temp.password = ""
            }

            if(validator.isEmpty(fieldValues?.confirmPassword)) {
                temp.confirmPassword = "This field is required."
            }else if(fieldValues?.password !== fieldValues?.confirmPassword) {
                temp.confirmPassword = "Password miss match."
            }else {
                temp.confirmPassword = ""
            }

            if(field === '') {
                setErrors(JSON.parse(JSON.stringify(temp)))
            }else {
                setErrors({...errors, [field]: temp[field]  || ''})
            }
    
            return Object.values(temp).every(x => x === "")
    }

    const { values, errors, setErrors, resetForm, handleInputChange } = useForm({}, true, validate);

    const submitHandler = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
        if(validate()) {
            resetPassword(values?.password);
        }
	}

    useEffect(()=> {
        if(open === false) {
            resetForm()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const resetPassword = async (password: string) => {
        try {
            setProcessing(true);
            await authService.resetPassword(uid, password);
            setProcessing(false);
            handleClose();
            if(onSuccess) onSuccess();
        } catch (error: any) {
            handleClose();
            setProcessing(false);
            const errMsg = error?.message || 'Reset password failed'
            toast.error(dispatch, errMsg);
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
                <DialogTitle>Reset Password</DialogTitle>
                <DialogContent>
                    <Form onSubmit={submitHandler}>
                        <Grid container mt={3}>
                            <Grid item sm={12}>
                                <Controls.Input
                                    name="password"
                                    label="New password"
                                    value={values?.password || ''}
                                    onChange={handleInputChange}
                                    required
                                    fullWidth
                                    type="password"
                                    error={!!errors['password']}
                                    helperText={errors['password']}
                                    sx={{ marginBottom: 3 }}
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <Controls.Input
                                    name="confirmPassword"
                                    label="Confirm password"
                                    value={values?.confirmPassword || ''}
                                    onChange={handleInputChange}
                                    type="password"
                                    required
                                    fullWidth
                                    error={!!errors['confirmPassword']}
                                    helperText={errors['confirmPassword']}
                                    sx={{ marginBottom: 3 }}
                                />
                            </Grid>
                        </Grid>

                        <Stack direction="row" marginTop={4} justifyContent="flex-end" spacing={2}>
                            <Button onClick={handleClose}>Cancel</Button>
                            <LoadingButton loading={processing} type="submit" variant="contained">Reset</LoadingButton>
                        </Stack>
                    </Form>
                </DialogContent>
            </Dialog>    
        </ThemeProvider>
    )
}

export default ResetPasswordModal;
