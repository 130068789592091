import { NotificationsActive } from '@mui/icons-material';
import { Badge, IconButton, List, ListItem, ListItemText, Popover, Typography } from '@mui/material';
import  { MouseEvent, useState } from 'react'
import RouterLink from '../RouterLink';

const Notifications = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
  
    const handleClick = (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
      setAnchorEl(null);
    };
  return (
    <>
        <IconButton aria-label="announcement" sx={{ marginRight: 3 }} onClick={handleClick}>
            <Badge color="error" sx={(theme)=> ({
                 '& .MuiBadge-badge': {
                    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
                    height: '1rem',
                    minWidth: '1rem',
                    '&::after': {
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        borderRadius: '50%',
                        animation: 'ripple 1s infinite ease-in-out',
                        border: '2px solid currentColor',
                        content: '""',
                    },
                    '@keyframes ripple': {
                        '0%': {
                          transform: 'scale(.6)',
                          opacity: 1,
                        },
                        '100%': {
                          transform: 'scale(2.4)',
                          opacity: 0,
                        },
                    },
                }
            })} badgeContent=" ">
                <NotificationsActive />
            </Badge>
        </IconButton>
        <Popover
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            sx={{ paddingY: 0 }}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    borderRadius: 0,
                    width: '25rem',
                    mt: .5,
                    '& .MuiList-root': {
                        paddingTop: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <List>
                <ListItem>
                    <ListItemText
                        // primary={"Add Sink"}
                        primary={<Typography fontWeight={600}>Add Sink</Typography>}
                        secondary={(
                            <Typography variant="body2">
                                Please configure a sink type for receiving message in your Cloud Pub/Sub topics
                                <RouterLink onClick={handleClose} sx={{ ml: 1 }} color="#0072e5" to="/sinks/add">Click here to configure</RouterLink>
                            </Typography>
                        )}
                    />
                </ListItem>
            </List>
        </Popover>
    </>
  )
}

export default Notifications