import { Alert, Box, Collapse, Typography } from '@mui/material';
import { useState } from 'react';
import RenderElement from './RenderElement';
import { Announcement } from '@mui/icons-material';
import { useStore } from '../store';

interface Props {
    subscription: string
}

const AnnouncementBanner = (props: Props) => {
    const { subscription } = props;
    const [open, setOpen] = useState(true);

    const { globalState: { subscriptionDetails } } = useStore();

    const Banners = subscriptionDetails?.flags?.Announcements || false;
    
    return (
        <RenderElement show={Banners}>
            <Collapse in={open}>
                <Alert severity='warning' icon={false} variant="filled" sx={{ position: 'absolute', left: 0, right: 0, zIndex: 2000, borderRadius: 0, '.MuiAlert-message': {
                    flexGrow: 1
                }}} onClose={() => { setOpen(false) }}>
                    <Box display="flex" justifyContent="center">
                        <Announcement sx={{ mr: 2}} />
                        <Typography variant="body2" textAlign="center" fontWeight={500}>{Banners ? "" : ""}</Typography>
                    </Box>
                </Alert>
            </Collapse>
        </RenderElement>
    )
}

export default AnnouncementBanner