import api from './api'

class APIKeyService {

    getAPIKey = (subscriptionId: string) => {
        return api.get(`/subscriptions/${subscriptionId}/api-keys`);
    }

    listClients = (subscriptionId: string) => {
        return api.get(`/subscriptions/${subscriptionId}/clients`);
    }

    createClient = (subscriptionId: string, data: any) => {
        return api.post(`/subscriptions/${subscriptionId}/clients`, data);
    }

    getSecret = (subscriptionId: string, appId: string) => {
        return api.get(`/subscriptions/${subscriptionId}/clients/secret/${appId}`);
    }

    deleteKey = (subscriptionId: string, appId: string) => {
        return api.delete(`/subscriptions/${subscriptionId}/clients/${appId}`);
    }

    // addIPRanges = (appId: string, ipRange: Array<any>) => {
    //     const data = { appId, ipRange };
    //     return api.patch(`/client`, data);
    // }

    getToken = () => {
        
    }

}

export default new APIKeyService();
