import { CssBaseline } from '@mui/material';
import { useEffect } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';

import Header from '../components/header/header';
import Toast from '../components/Toast';
import AppRoutes from '../routes/app-routes';
import { GlobalStore, useStore } from '../store';
import styles from './App.module.scss';

import { auth, fetchAndActivateRemoteConfig } from '../config/firebase'
import apikeyMtService from '../services/apikey.mt.service';
import { useAuthState } from 'react-firebase-hooks/auth';
import AnnouncementBanner from '../components/AnnouncementBanner';


const AppLayout = () => {
  const location = useLocation();
  const { dispatch, globalState: { selectedSubscriptionId } } = useStore();

  const [user] = useAuthState(auth);

  const getAPIKey = async() => {
    try {
      const resp = await apikeyMtService.getAPIKey(selectedSubscriptionId)
      if(resp?.data?.data?.length) {
        const key = resp.data.data[0].apiKey || '';
        dispatch({ type: 'SET_API_KEY', payload: key })
        sessionStorage.setItem('key', key);
      }
    } catch (error) {
      
    }
  }

  useEffect(() => {
    if(selectedSubscriptionId && user) {
      getAPIKey();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubscriptionId, user])
  

  return (
    <>
      <AnnouncementBanner subscription={selectedSubscriptionId} />
      {
        !['/login', '/reset-password', '/forgot-password'].includes(location.pathname) &&
        <Header />
      }
      <main id={styles.MainContainer}>
        <AppRoutes />
      </main>
    </>
  )
}


function App() {
  useEffect(() => {
    fetchAndActivateRemoteConfig();
  }, [])
  

  return (
    <GlobalStore>
      <BrowserRouter>
          <CssBaseline />
            <AppLayout />
          <Toast />
      </BrowserRouter>
    </GlobalStore>
  );
}

export default App;
