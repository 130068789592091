
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText, SelectProps as MuiSelectProps, SvgIcon, ListSubheader } from '@mui/material';

type OptionType = {
    id: string | number,
    title: string,
    disabled?: boolean,
    icon?: any,
    iconColor?: string,
}

type OptionGroupType = {
    category: string,
    options:  Array<OptionType>
}

interface Props extends Omit<MuiSelectProps, 'value' | 'onChange' | 'children'> {
    options: Array<OptionGroupType>;
    value: string | number;
    onChange: any;
    error?: boolean;
    helperText?: string;
}

const labelStyle= { 
    transform: 'translate(14px, 9px) scale(1)',
    '&.MuiFormLabel-filled, &.Mui-focused': {
        transform: 'translate(14px, -9px) scale(0.75)',
    }
}




export default function GroupSelect(props: Props) {
    
    const { name, label, value,error, sx, onChange, options, size, placeholder, helperText, required, disabled } = props;
    
    const Options = options?.map((item,index) => {        
        let cat = <ListSubheader key={index} sx={{ color: '#8c8c8c', lineHeight: '28px' }}>{name?.includes("Stream")?"AWS Account: ":""}<em>{item?.category}</em></ListSubheader>
        let options = item?.options?.map(option => (
            <MenuItem key={option.id} disabled={option?.disabled} value={option.id} sx={{ fontSize: '95%', color: '#000', pl: 3 }}>
                {option?.icon &&
                    <SvgIcon inheritViewBox component={option?.icon} fontSize="small" sx={{ marginRight: 2, color: option?.iconColor }} />
                }
                {option.title}
            </MenuItem>
        ));

        return [cat, ...options]
    })

    return (
        <FormControl variant="outlined" fullWidth size={size} error={error}>
            <InputLabel sx={[labelStyle]}>{label}</InputLabel>
            <MuiSelect
                label={label}
                name={name}
                value={value}
                size={size || 'small'}
                placeholder={placeholder}
                required={required}
                disabled={disabled}
                sx={sx}
                onChange={onChange}>
                {
                    !required &&
                    <MenuItem value="">None</MenuItem>
                }
                { Options.reduce((acc, val) => acc.concat(val), []) }
            
            </MuiSelect>
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    )
}
