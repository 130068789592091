import { Alert, Snackbar } from '@mui/material'
import { Dispatch } from 'react'
import { useStore } from '../store';
import { ActionType, ToastObjecType } from '../store/types';

const Toast = () => {
  const { globalState: { toastObject }, dispatch } = useStore()

  const handleClose = () => {
    dispatch({ type: 'SET_TOAST', payload: {} })
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={toastObject?.open}
      onClose={handleClose}
      autoHideDuration={3000}
    >
      <Alert onClose={handleClose} variant="filled" severity={toastObject?.type} sx={{ width: '100%' }}>
        {toastObject?.message}
      </Alert>
    </Snackbar>
  )
}

const errorToast = (dispatch: Dispatch<ActionType>, message: string) => {
  const payload: ToastObjecType = {
    open: true,
    message,
    type: 'error',
  }
  dispatch({
    type: 'SET_TOAST',
    payload
  })
}

const successToast = (dispatch: Dispatch<ActionType>, message: string) => {
  const payload: ToastObjecType = {
    open: true,
    message,
    type: 'success',
  }
  dispatch({
    type: 'SET_TOAST',
    payload
  })
}

const infoToast = (dispatch: Dispatch<ActionType>, message: string) => {
  const payload: ToastObjecType = {
    open: true,
    message,
    type: 'info',
  }
  dispatch({
    type: 'SET_TOAST',
    payload
  })
}

export const toast = {
  error: errorToast,
  success: successToast,
  info: infoToast
}

export default Toast