import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import GroupSelect from "./GroupSelect";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import DateTimePicker from "./DateTimePicker";
import Button from "./Button";

const Controls = {
    Input,
    RadioGroup,
    Select,
    GroupSelect,
    Checkbox,
    DatePicker,
    DateTimePicker,
    Button
}

export default Controls;