import { createContext, ReactElement, ReactNode, useContext, useEffect, useReducer, useRef } from 'react';
import Reducer from './reducer';
import { ContextType, GlobalStateInterface } from './types';

export const GlobalContext = createContext({} as ContextType);

/**
 * React Context-based Global Store with a reducer
 * and persistent saves to sessionStorage/localStorage
 **/
export function GlobalStore({ children }: { children: ReactNode }): ReactElement {
  const [globalState, dispatch] = useReducer(Reducer, initializeState());
  const initialRenderGlobalState = useRef(true);
  const initialRenderPersistenceType = useRef(true);

  useEffect(() => {
    /*
     populate either sessionStorage or localStorage
     data from globalState based on persistenceType
    */
    if (initialRenderGlobalState.current) {
      initialRenderGlobalState.current = false;
      return;
    }
    const getPersistenceType = globalState.persistenceType;
    if (getPersistenceType === 'sessionStorage') {
      sessionStorage.setItem('globalState', JSON.stringify(globalState));
    } else if (getPersistenceType === 'localStorage') {
      localStorage.setItem('globalState', JSON.stringify(globalState));
    }
  }, [globalState]);

  useEffect(() => {
    /*
     purge sessionStorage or localStorage when either is selected
    */
    if (initialRenderPersistenceType.current) {
      initialRenderPersistenceType.current = false;
      return;
    }
    const getPersistenceType = globalState.persistenceType;
    if (getPersistenceType === 'sessionStorage') {
      localStorage.removeItem('globalState');
    } else if (getPersistenceType === 'localStorage') {
      sessionStorage.removeItem('globalState');
    }
  }, [globalState.persistenceType]);

  return <GlobalContext.Provider value={{ globalState, dispatch }}>{children}</GlobalContext.Provider>;
}

export const useStore = () => useContext(GlobalContext);

export const initialState: GlobalStateInterface = {
  isUserAuthenticated: false,
  subscriptionList: [],
  selectedSubscriptionId: '',
  tenantDetails: {},
  userPermissions: [],
  currentUserRole: '',
  persistenceType: 'sessionStorage',
  subscriptionDetails: {},
  provider: '',
  toastObject: {},
  sidebarCollapsed: false,
  pubSubTopics: [],
  subscriptionProvisionStatus: '',
  apiKey: '',
  registry:{}
};

function initializeState() {
  /*
   the order in which the data is compared is very important;
   first try to populate the state from Storage if not return initialState
  */

  if (typeof (Storage) !== 'undefined') {
  } else {
    throw new Error('You need to enable Storage to run this app.');
  }

  const fromLocalStorage = JSON.parse(localStorage.getItem('globalState') as string);
  const fromSessionStorage = JSON.parse(sessionStorage.getItem('globalState') as string);
  return fromSessionStorage || fromLocalStorage || initialState;
}