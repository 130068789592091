import axios, { AxiosInstance } from 'axios';
import ENV from '../config/env';
import { auth } from '../config/firebase';

// const KEY = ``
class IdpApi {
    idp: AxiosInstance
    constructor() {

        this.idp = axios.create({
            baseURL: ENV.IDENTITY_API, // 'http://localhost:8000'
        })

        this.idp.interceptors.request.use(
            async (config: any) => {
                const token = await auth.currentUser?.getIdToken()
                if (token) {
                    config.headers['Authorization'] = 'Bearer ' + token
                }
                config.headers['Content-Type'] = 'application/json';
                return config
            },
            error => {
              Promise.reject(error)
            }
        )
    }

    get(url: string) {
        const URL = url
        return this.idp.get(URL);
    }

    post(url: string, params: any) {
        const URL = url
        return this.idp.post(URL, params);
    }

    put(url: string, data?: any) {
        const URL = url
        return this.idp.put(URL, data);
    }

    patch(url: string, data?: any) {
        const URL = url
        return this.idp.patch(URL, data);
    }

    delete(url: string, data?: any) {
        const URL = url
        return this.idp.delete(URL, {
            data
        });
    }
}

export default new IdpApi();
