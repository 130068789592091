import { AppBar, Divider, Grid, Toolbar } from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { auth, signOutHandler } from '../../config/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

import { ThemeProvider, createTheme } from '@mui/material';

import Logo from '../../assets/kore_logo.png';
import OmniCore from '../../assets/OmniCoreAvenir.png';
import { Box } from "@mui/system";

import AccountMenu from "./AccountMenu";
import { useEffect, useState } from "react";
import authService from "../../services/auth.service";
import Notifications from "./Notifications";
import RenderElement from "../RenderElement";
import { useStore } from "../../store";
import SubSelect from "./SubSelect";
import { permissions, Roles } from "../../global/constants";
import Search from "./Search";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: '#f58320'
    },
    secondary: {
      main: "#CB6100"
    }
  }
});

const Header = () => {

  const [user] = useAuthState(auth);
  const [iconClicked, setIconClicked] = useState<boolean>(false);


  const { globalState: { tenantDetails, subscriptionList, selectedSubscriptionId, subscriptionDetails, userPermissions, apiKey }, dispatch } = useStore();

  const navigate = useNavigate()

  const getTenantDetails = async () => {
    try {
      if (user?.tenantId) {
        const resp = await authService.getTenantDetails(user?.tenantId);
        if (resp?.data?.tenant) {
          dispatch({
            type: 'SET_TENANT',
            payload: resp?.data?.tenant
          })
        }
        if (resp?.data?.provider) {
          dispatch({ type: 'SET_DEFAULT_PROVIDER', payload: resp?.data?.provider?.provider });
        }
      }
    } catch (error) {

    }
  }

  const getSubscription = async () => {
    try {
      const resp = await authService.getSubscription(selectedSubscriptionId)
      const flagResp = await authService.getSubscriptionFlags(selectedSubscriptionId)
      const sub = subscriptionList.find((s: any) => s.subscription_id === selectedSubscriptionId);
      if (resp?.data && flagResp?.data) {
        dispatch({
          type: 'SET_SUBSCRIPTION_DETAILS',
          payload: { ...resp?.data, subscription_alias: sub?.subscription_alias ? sub?.subscription_alias : selectedSubscriptionId ,flags: flagResp?.data}
        })
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    if (selectedSubscriptionId && user && apiKey) {
      getSubscription()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubscriptionId, user?.uid, apiKey])

  const getSubscriptions = async () => {
    try {
      const resp = await authService.getSubscriptions();
      dispatch({
        type: 'SET_SUBSCRIPTION_LIST',
        payload: resp?.data?.subscriptions
      });

      let subscription = queryParams.get('subscription') || '';
      if (!subscription && resp?.data?.subscriptions?.length) {
        subscription = resp?.data?.subscriptions[0].subscription_id;

        const queryParams = getQueryParams();
        setQueryParams({ ...queryParams, subscription: resp?.data?.subscriptions[0].subscription_id });
      }

      type roleType = 'Admin' | 'Editor' | 'Viewer' | 'Provisioner' | 'DeviceController';
      await authService.setAccessControl(subscription);
      const tokenResult: any = await user?.getIdTokenResult(true);
      const role: roleType = tokenResult?.claims?.role
      const permissions = Roles[role]

      dispatch({ type: 'SET_SUBSCRIPTION', payload: subscription });
      dispatch({ type: 'SET_USER_PERMISSIONS', payload: permissions })
      dispatch({ type: 'SET_USER_ROLE', payload: role });

    } catch (error) {

    }
  }
  useEffect(() => {
    if (user) {
      getTenantDetails();
      getSubscriptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.uid])

  const subscriptionOptions = subscriptionList?.map(s => ({ id: s?.subscription_id, title: s?.subscription_id, tenant: s?.tenant_id, isPartner: s?.isPartner, logo: s?.logo, ...s }))

  const [queryParams, setQueryParams] = useSearchParams();
  const getQueryParams = () => {
    const params = queryParams.entries();
    return Array.from(params).reduce((pre: any, [a, b]: any) => {
      pre[a] = b;
      return pre
    }, {})
  }

  const subscriptionSelectionHandler = async (e: any) => {
    const { value } = e?.target;

    type roleType = 'Admin' | 'Editor' | 'Viewer' | 'Provisioner' | 'DeviceController' | 'TenantAdmin';
    await authService.setAccessControl(value);
    const tokenResult: any = await user?.getIdTokenResult(true);
    const role: roleType = tokenResult?.claims?.role
    const permissions = Roles[role]
    dispatch({ type: 'SET_USER_PERMISSIONS', payload: permissions });
    dispatch({ type: 'SET_USER_ROLE', payload: role });
    const queryParams = getQueryParams();
    setQueryParams({ ...queryParams, subscription: value });
    dispatch({ type: 'SET_API_KEY', payload: '' })
    dispatch({
      type: 'SET_SUBSCRIPTION',
      payload: value
    })
    navigate(`/dashboard?subscription=${value}`);
    dispatch({ type: 'SET_SUBSCRIPTION_PROVISION_STATUS', payload: '' })
  }

  const logoutHandler = () => {
    signOutHandler();
    dispatch({ type: 'PURGE_STATE', payload: null })
  }

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static" sx={{ borderBottom: '1px solid #cdcdcd' }}>
        <Toolbar>
          {/* <IconButton color="inherit" onClick={toggleSidebar}>
              <MenuIcon />
            </IconButton> */}
          <Box display="flex" sx={{ flexGrow: 1 }}>
            <Link to={`/dashboard?subscription=${selectedSubscriptionId}`} style={{ display: 'flex', alignItems: 'center' }}>
              <img src={Logo} alt="Logo" height={40} />
              <Divider variant="middle" orientation="vertical" sx={{ mx: 1, height: '80%', borderRightWidth: 'medium' }} />
              <img src={OmniCore} alt="OmniCore" height={20} />
            </Link>
            <RenderElement show={!!user}>
              <Grid container flexGrow={1} ml={6}>
                <Grid item sm={4}>
                  <SubSelect
                    options={subscriptionOptions}
                    value={selectedSubscriptionId}
                    tenant={user?.tenantId}
                    onChange={subscriptionSelectionHandler}
                  />
                </Grid>
              </Grid>
            </RenderElement>
            {/* <RenderElement show={!!user}>
              <Search iconClicked={iconClicked} setIconClicked={setIconClicked} />
            </RenderElement> */}
          </Box>
          <RenderElement show={!!user}>
            <RenderElement show={subscriptionDetails?.connectorPresent === false && userPermissions.includes(permissions.createSink)}>
              <Notifications />
            </RenderElement>
            <AccountMenu user={user} onLogout={logoutHandler} tenantDetails={tenantDetails} isOpenPopup={iconClicked} />
          </RenderElement>
        </Toolbar>
      </AppBar>
    </ThemeProvider >
  )
}

export default Header;
