// import { useKeycloak } from "@react-keycloak/web";
import { Navigate } from 'react-router-dom';

import { auth } from '../config/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useStore } from '../store';
import PageLoader from './PageLoader';

const PrivateRoute = (props: any) => {
    // const { keycloak } = useKeycloak();

    const [user] = useAuthState(auth);
    const { globalState: { apiKey } } = useStore()

    const {children } = props;

    const isLoggedIn = !!user;

    if(!apiKey && isLoggedIn) {
        return <PageLoader />
    }
    
    // const url = target ? target : '/'
    return isLoggedIn ? children : (<Navigate to={`/login`} />);
};

export default PrivateRoute;

