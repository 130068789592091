import { SvgIcon, SvgIconProps } from '@mui/material'

const AverageIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="800px" height="800px" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props} >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="drop" fill="#000000" transform="translate(60.496777, 60.491376)">
                <path d="M362.038672,4.40536496e-13 L392.208561,30.1698893 L330.849151,91.530257 C352.999344,120.319635 366.169889,156.376056 366.169889,195.508624 C366.169889,289.765221 289.75982,366.17529 195.503223,366.17529 C156.370421,366.17529 120.313802,353.004587 91.5243389,330.854154 L30.1698893,392.208561 L-1.42108547e-14,362.038672 L61.2047929,300.834525 C38.4229489,271.826458 24.836556,235.254177 24.836556,195.508624 C24.836556,101.252026 101.246625,24.841957 195.503223,24.841957 C235.248317,24.841957 271.820213,38.4280364 300.82812,61.2094052 L362.038672,4.40536496e-13 Z M300.334816,122.043508 L122.038107,300.340217 C142.831803,314.939228 168.166768,323.508624 195.503223,323.508624 C266.195671,323.508624 323.503223,266.201072 323.503223,195.508624 C323.503223,168.172169 314.933827,142.837204 300.334816,122.043508 Z M195.503223,67.5086237 C124.810775,67.5086237 67.5032227,124.816176 67.5032227,195.508624 C67.5032227,223.460895 76.4630581,249.320485 91.66741,270.372075 L270.365674,91.6720892 C249.314266,76.4681756 223.455052,67.5086237 195.503223,67.5086237 Z" id="Combined-Shape">
                </path>
            </g>
        </g>
    </SvgIcon>
  )
}

export default AverageIcon;